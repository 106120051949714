import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  Group,
  Center,
  Stack,
  Anchor,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import AppLogo from '../components/AppLogo';
import settings from '../config/settings';

const AlertClosedPage = ({ title }) => {
  const { classes } = useStyles();

  const navigation = useNavigate();

  const homeAddress = settings.homeAddress;

  const handleOnHome = () => {
    console.log(`https://${homeAddress}`);
    window.location.replace(`https://${homeAddress}`);
  };

  return (
    <Stack align="center" justify="center" className={classes.root}>
      <Center>
        <AppLogo size={50} />
      </Center>
      <Title className={classes.title}>
        {title ? title : 'Alert is closed'}
      </Title>

      <Group position="center">
        <Button size="md" color="red" onClick={handleOnHome}>
          Home
        </Button>
      </Group>
    </Stack>
  );
};

export default AlertClosedPage;

const useStyles = createStyles(theme => ({
  root: {
    height: '100%',
  },

  inner: {
    position: 'relative',
  },

  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 0,
    opacity: 0.75,
  },

  content: {
    paddingTop: 220,
    position: 'relative',
    zIndex: 1,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: 120,
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,
    color: 'white',
    [theme.fn.smallerThan('sm')]: {
      fontSize: 28,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));
